import './Home.scss';

import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import Carousel from 'react-spring-3d-carousel';

import arrowLeft from '../../assets/arrowcircleleft.png';
import bottom from '../../assets/GroupbottomPink.png';
import top from '../../assets/GrouptopPink.png';
import minigame from '../../assets/minigame.png';
import kryp from '../../assets/kryptos.png';
import play from '../../assets/play2022.png';
import wordleIcon from '../../assets/wordle.png';
import HomeHeader from '../../components/common/HomeHeader/HomeHeader';
import Spinner from '../../components/common/Spinner/Spinner';
import Games from '../../components/Home/Games/Games';
import { ApiRoot, MiniRoot, SpellRoot } from '../../config/api';
import * as http from '../../config/http';

export const fetchUserRank = async (refresh_token: string) => {
	await http.generateJwtToken(refresh_token);
	if (refresh_token) {
		return http.get(`${ApiRoot}/api/getrank`);
	}
};

const Home = () => {
	const [kryptosRank, setKryptosRank] = useState(0);
	const [dalalbullRank, setDalalbullRank] = useState(0);
	const [circuimstanceRank, setCircuimstanRank] = useState(0);
	const [userPic, setUserPic] = useState('');
	const [username, setUserName] = useState('');
	const [auth, setAuth] = useState(false);
	const [miniUser, setminiUser] = useState({
		ranks: {
			doodle: 0,
		},
	});
	const [device, setDevice] = useState('desktop');
	const token = useSelector((store: any) => store.auth.token);
	const [load, setLoad] = useState(true);
	const [goToSlide, setGoToSlide] = useState(0);
	const [xDown, setXDown] = useState(null);
	const [yDown, setYDown] = useState(null);
	const [wordleRank, setWordleRank] = useState(0);

	async function getDoodleJumpRank() {
		try {
			const doodleBackendBaseUrl =
				process.env.REACT_APP_DOODLE_BACKEND_BASE_URL;
			if (doodleBackendBaseUrl && token) {
				const response = await http.get(
					`${doodleBackendBaseUrl}/doodle/score`
				);
				setminiUser({ ranks: { doodle: response.rank } });
				// console.log(response);
			} else {
				// console.log('Doodle rank not fetched');
			}
		} catch (error) {
			console.log('Error fetching user doodle rank', error);
			setminiUser({ ranks: { doodle: undefined } });
		}
	}

	async function getWordleRank() {
		try {
			const wordleBackendBaseUrl =
				process.env.REACT_APP_WORDLE_BACKEND_BASE_URL;
			if (wordleBackendBaseUrl && token) {
				const response = await http.get(`${wordleBackendBaseUrl}/rank`);
				setWordleRank(response);
			} else {
				setWordleRank(0);
			}
		} catch (error) {
			console.log('Error fetching user wordle rank', error);
			setWordleRank(undefined);
		}
	}

	useEffect(() => {
		http.getUser(token).then((data) => {
			// console.log(data)

			if (!(Object.getOwnPropertyNames(data).length === 0)) {
				setUserPic(data.picture);
				setUserName(data.name);

				// This was for Exagon
				// http.getMini(`${MiniRoot}/user/${data.email}`).then((d) => {
				//   if (d.length == 0) {
				//     http.postMini(`${MiniRoot}/create_user?email=${data.email}&name=${data.name}`, '')
				//       .then(da => setminiUser({ scores: da.user.scores }))
				//       .catch(err => console.log(err))
				//   }
				//   else {
				//     setminiUser(d[0])
				//   }

				// }).catch(err => console.log(err))

				// http.getSpellRank(`${SpellRoot}/getRankbyEmail`, data.email).then((s) => {
				//   if('rank' in s){
				//     setSpellRank(s.rank)
				//   }
				// })
				setAuth(true);

				try {
					fetchUserRank(token).then((data) => {
						// console.log(data)
						if (
							typeof data === 'number' &&
							data >= 0 &&
							data != undefined
						) {
							setKryptosRank(data);
						}
					});
				} catch (error) {
					console.log('Error fetching user kryptos rank', error);
					setKryptosRank(0);
				}

				getDoodleJumpRank();
				getWordleRank();
			}
		});
	}, [token]);

	useEffect(() => {
		setTimeout(() => {
			setLoad(false);
		}, 2000);

		if (window.innerWidth < 1000) {
			setDevice('mobile');
		}
	}, []);

	useEffect(() => {
		setInterval(() => {
			setGoToSlide((x) => x + 1);
		}, 8000);
	}, []);

	const CarouselCard = ({ details }) => {
		let rankStr: string = '';
		if (typeof details.rank === 'number') {
			rankStr = details.rank.toString();
		} else if (typeof details.rank === 'string') {
			rankStr = details.rank;
		} else {
			rankStr = '-';
		}

		return (
			<div
				className='carouselCard'
				onClick={() => {
					window.location.href = details.href;
				}}
			>
				<div className='rankBox'>
					<div className='title'>
						<p style={{ fontSize: '15px' }}>{rankStr}</p>
						<p>{'Rank'}</p>
					</div>
				</div>
				<div className='icon'>
					<img src={details.icon} width='100px' />
				</div>
				<div className='gameName'>{details.name}</div>
				<div className='playButton'>Play</div>
			</div>
		);
	};

	const slides = [
		{
			key: 1,
			content: (
				<CarouselCard
					details={{
						name: 'KRYPTOS',
						icon: kryp,
						rank: kryptosRank,
						href: 'Kryptos',
					}}
				/>
			),
		},
		{
			key: 2,
			content: (
				<CarouselCard
					details={{
						name: 'WORDLE',
						icon: wordleIcon,
						rank: wordleRank,
						href: 'wordle',
					}}
				/>
			),
		},
		{
			key: 3,
			content: (
				<CarouselCard
					details={{
						name: 'DOODLE JUMP',
						icon: minigame,
						rank: miniUser?.ranks?.doodle,
						href: 'doodlejump',
					}}
				/>
			),
		},
	];

	const getTouches = (evt) => {
		return evt.touches || evt.originalEvent.touches;
	};

	const handleTouchStart = (e) => {
		console.log('Touch start');
		const firstTouch = getTouches(e)[0];
		setXDown(firstTouch.clientX);
		setYDown(firstTouch.clientY);
	};

	const handleTouchMove = (evt) => {
		evt.preventDefault();
		console.log('Touch end');
		if (!xDown && !yDown) {
			return;
		}

		let xUp = evt.touches[0].clientX;
		let yUp = evt.touches[0].clientY;

		let xDiff = xDown - xUp;
		let yDiff = yDown - yUp;
		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			if (xDiff > 0) {
				setGoToSlide((x) => x + 1);
				setXDown(null);
				setYDown(null);
			} else {
				setGoToSlide((x) => x - 1);
				setXDown(null);
				setYDown(null);
			}
		}
	};

	// useEffect(() => {
	//   console.log(miniUser)
	// },[miniUser])
	if (load) {
		return <Spinner />;
	} else {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					overflowX: 'scroll',
					overflowY: 'scroll',
				}}
				className='main'
			>
				<img src={top} className='top' width={'30%'} />
				<img src={play} className='play' />
				<img src={bottom} className='bottom' width={'30%'} />
				<div className=''>
					<HomeHeader
						username={username}
						userPic={userPic}
						isAuth={auth}
					/>
				</div>
				<div className='container'>
					{device === 'desktop' ? (
						<div className='events'>
							<div className='flexbox'>
								<div className=' center-block text-center cell'>
									<Games
										name='KRYPTOS'
										rank={kryptosRank}
										logo={kryp}
										type='ranked'
										href='Kryptos'
										status='Play Now'
										index={1}
									/>
								</div>

								<div className=' center-block text-center cell'>
									<Games
										name='WORDLE'
										rank={wordleRank}
										logo={wordleIcon}
										type='ranked'
										href='wordle'
										status='Play Now'
										index={1}
									/>
								</div>
								<div className='center-block text-center cell'>
									<Games
										name='DOODLE JUMP'
										rank={miniUser?.ranks?.doodle}
										logo={minigame}
										type='ranked'
										href='/doodlejump'
										status='Play Now'
										index={1}
									/>
								</div>
							</div>
						</div>
					) : (
						<div>
							<div
								onTouchStart={handleTouchStart}
								onTouchMove={handleTouchMove}
								style={{ height: '60vh', width: '90vw' }}
							>
								<Carousel
									goToSlide={goToSlide}
									showNavigation={false}
									slides={slides}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-around',
									marginTop: '3rem',
								}}
							>
								<div>
									<img
										onClick={() =>
											setGoToSlide((i) => i - 1)
										}
										src={arrowLeft}
										className={'left'}
									/>
								</div>
								<div>
									<img
										onClick={() =>
											setGoToSlide((i) => i + 1)
										}
										src={arrowLeft}
										className={'right'}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
};

export default Home;
