import React from 'react';
import loader from '../../../assets/play2.gif'

const Spinner = () => {
  function detectMob() {
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
  }
  return(
  
  <div style={{ width : '100vw', height: '100vh', background: '#000'}}>
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <img src={loader} width={detectMob() ? '260px' : '140px'}/>
    </div>
  </div>
)};

export default Spinner;
