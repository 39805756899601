import React from 'react';
import './Wordle.css';

export default function Wordle() {
	const refreshToken = localStorage.getItem('refreshToken');
	let frameUrl = new URL(process.env.REACT_APP_WORDLE_URL || '/');

	if (refreshToken) {
		frameUrl.searchParams.set('refreshToken', refreshToken);
	}

	return (
		<div className='wordle-wrapper'>
			<iframe
				className='wordle-iframe'
				src={frameUrl.toString()}
				title='Excel PLAY | Wordle'
			/>
		</div>
	);
}
