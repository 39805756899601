import React, { useEffect, useState } from 'react';
import './KryptosQuestion.scss';
import { IMAGE_FAILED_TO_LOAD } from '../../../components/common/Constants';

type Props = {
  imgUrl: string
  sourceHint: string
  onSubmit: any
  image_level: boolean
  loading: boolean
}

const KryptosQuestion = (props: Props) => {
  const [text, setText] = useState('');
  const { imgUrl, sourceHint, onSubmit, image_level } = props;
  useEffect(() => {
    // console.log(sourceHint)
  }, [])

  return (
    <div className="questionWrapper pb-5">
      {image_level ? (
        <div className='loading'>
        <img
          src={imgUrl}
          className="img img-fluid"
          />
        </div>
        
      ) : null}
      <div className={`sourceHint ${image_level?'':'noImageQn'}`}>{sourceHint}</div>
      <div className='bottomAnswer'>
        <div className="answerWrapper">
          <input
            className="answer"
            placeholder="Your Answer"
            //onChange={(txt: { target: { value: string; }; }) => setText(txt.target.value.toLowerCase())}
            onChange={(txt: { target: { value: string; }; }) => setText(txt.target.value)}
          />
          <button
            disabled={props.loading}
            type="button"
            className="submit__btn"
            onClick={() => onSubmit(text)}
          >
            Submit
          </button>
        </div>
        <button
          id="hint_btn"
          type="button"
          className="btn font-weight-bold border-0 mt-2"
          data-toggle="modal"
          data-target="#hint-modal"
        >
          View Hints
        </button>
      </div>
    </div>
  );
};

export default KryptosQuestion;
