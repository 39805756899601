import React from 'react';
import './DoodleJump.css';

export default function DoodleJump() {

    const refreshToken = localStorage.getItem('refreshToken');
    let frameUrl = new URL(process.env.REACT_APP_DOODLE_URL || '/');

    if (refreshToken) {
        frameUrl.searchParams.set('refreshToken', refreshToken);
    }

	return (
		<div className='doodle-wrapper'>
			<iframe
				className='doodle-iframe'
				src={frameUrl.toString()}
				title='Excel PLAY | Doodle Jump'
			/>
		</div>
	);
}
