import {
    KryptosReducerType,
    KryptosActionType,
    SET_RANK_LIST,
    SET_KRYPTOS_QUESTION
} from "./Kryptos.types";

let initialState: KryptosReducerType = {
    ranklist: [
        {
            name:'Participant 1',
            pic:'www.google.com',
            profile_pic:'wwww.google.com',
            rank:'2',
            curr_level:'2',
            showRanklist:true

        },
        {
            name:'Participant 2',
            pic:'www.google.com',
            profile_pic:'wwww.google.com',
            rank:'2',
            curr_level:'2',
            showRanklist:true

        },
        {
            name:'Participant 3',
            pic:'www.google.com',
            profile_pic:'wwww.google.com',
            rank:'2',
            curr_level:'2',
            showRanklist:true

        }
    ],
    question: {
        question: 'Loading',
        number: 0,
        hints: ['Hint 1'],
        level_file: 'Loading',
        image_level: true,
    }
};

const reducer = (state = initialState, action: KryptosActionType) => {
    switch (action.type) {
        case SET_RANK_LIST:
            return {
                ...state,
                ranklist: action.payload,
            };
        case SET_KRYPTOS_QUESTION:
            return {
                ...state,
                question: {
                    ...action.payload
                }
            }
        default:
            return state;
    }
}

export default reducer;