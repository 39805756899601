import React, { useEffect, useState } from "react";
import "../../../App.scss";
import "./KryptosPlay.scss";
import KryptosInfoBar from "../KryptosInfoBar/KryptosInfoBar";
import KryptosQuestion from "../KryptosQuestion/KryptosQuestion";
import KryptosHintModal from "../KryptosHintModal/KryptosHintModal";
import KryptosRanklist from "../KryptosRanklist/KryptosRanklist";
import load from '../../../assets/ss.gif'
import {
  MESSAGE_WHEN_CORRECT_ANSWER,
  MESSAGE_WHEN_WRONG_ANSWER,
  //NO_HINTS,
} from "../../../components/common/Constants";
import { fetchQuestion } from "../KryptosApi/ApiCalls";
import { useSelector, useDispatch } from "react-redux";
import { rootType } from "../../../store/Reducers/rootReducer";
import { submitKryptosAnswer } from "../KryptosApi/ApiCalls";
import { selectKryptosQuestion } from "../../../store/KryptosReducer/Kryptos.selectors";
import { setKryptosQuestion } from "../../../store/KryptosReducer/Kryptos.actions";

interface Props {
  showRanklist: boolean;
}

const KryptosPlay = (props: Props) => {
  //=====FOR USING DUMMY DATA==========//
  const [rank, setRank] = useState(0);

  const {
    question: sourceHin,
    level_file: imgUr,
    image_level,
    hints: hintText,
    number: level,
  } = useSelector(selectKryptosQuestion);

  const dispatch = useDispatch();

  const [gameOver, setGameOver] = useState(false);
  const [sourceHint, setSourceHint] = useState(sourceHin);
  const [imgUrl, setImgUrl] = useState(imgUr)

  // useEffect(() => {
  //   console.log("RLShowInKP", props.showRanklist)
  // }, [props.showRanklist])

  const token = useSelector((store: rootType) => store.auth.token);
  useEffect(() => {
    fetchQuestion(token).then((data) => {
      // console.log(data);
      if (data.number == -1) {
        window.alert("Congratulations for completing Kryptos 2023");
        // setTimeout(() => {
        //   window.location.replace('/');
        // }, 1000);
       setGameOver(true);
      }
      dispatch(setKryptosQuestion(data));
      if(data.image_level==true){
        setImgUrl(data.level_file);
        setSourceHint(data.question);
      }
      else{
        setSourceHint(data.question);
      }
    });
  }, [dispatch, token]);

  const [loading, setLoading] = useState(false);

  const onSubmit = (ans: string) => {
    console.log("Submitting")
    setLoading(true);
    submitKryptosAnswer(ans, token).then((data) => {
      // console.log(data);
      if (data.answer === "correct") {
        window.alert(MESSAGE_WHEN_CORRECT_ANSWER);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        window.alert(MESSAGE_WHEN_WRONG_ANSWER);
      }
      setLoading(false);
    });
    //====TEMPORARY, CHECKING IF ANSWER IS CORRECT====//
    // if (ans === "correct") {
    //   window.alert(MESSAGE_WHEN_CORRECT_ANSWER);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    // } else {
    //   window.alert(MESSAGE_WHEN_WRONG_ANSWER);
    // }
  };
  function detectMob(){
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
  }

  if (gameOver) {
    return <GameOverComponent />;
  }
  // else if((imgUrl == "Loading")){
  //   return (
  //     <div className="loader">
  //       <img src={load} width={detectMob() ? '60%' :'30%'} />
  //     </div>
  //   )
  // }

  return (
    <div>
      <div className="row">
        <div className="col">
          <KryptosInfoBar level={level} rank={rank} />
        </div>
      </div>
      <div className="kryptos-page row">
        <div className="col-lg-12">
          {<KryptosQuestion
            image_level={image_level}
            imgUrl={imgUrl}
            sourceHint={sourceHint}
            onSubmit={(ans: string) => onSubmit(ans)}
            loading={loading}
          />}
        </div>
        <div className={"abs-rank"} style={{ width: "30vw" }}>
          <span className="rank">
            <KryptosRanklist final={false} showRanklist={props.showRanklist} />
          </span>
        </div>
      </div>
      <KryptosHintModal hintText={hintText} />
    </div>
  );
};

export default KryptosPlay;

const GameOverComponent = () => (
  <div className={"winner-screen"}>
    <div className={"row"}>
      <div className={"col text-center"}>
        <p className={"font-weight-bold title"}>
          Congratulations on Completing Kryptos 2023!
        </p>
        <p className={"caption mt-3 mb-3"}>Thank you for playing!</p>
      </div>
    </div>
    <div className={"row top-blue w-100 ml-0 mt-2"}>
      { <KryptosRanklist showRanklist = {true} final = {true}/>}
    </div>
  </div>
);
