import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import {AuthRoot, AERoot} from '../../../config/api';
import Spinner from '../Spinner/Spinner';

interface PrivateRouteProps extends RouteProps {
  component: any;
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  // useEffect(() => {
  //   const rt = window.localStorage.getItem('refreshToken');
  //   if(!rt){
  //     isAuthenticated = false
  //   }
  //   else{
  //     isAuthenticated = true
  //   }
  // },[])
  const [auth, setAuth] = useState(null)

  // let { isAuthenticated } = useSelector(
  //   (state: any) => {
  //     console.log(state.auth.token)
  //     return {
  //       isAuthenticated: state.auth.token,
  //       // isAuthenticated: "YES"   //TEMPORARY MODIFIED FOR ADDING DUMMY DATA. Remove this and uncomment previous line when integrating with backend.
  //     };
  //   }
  // );
  const noAuth = ["/"]
    let isAuth: any = null;
  useEffect(() => {
    isAuth = window.localStorage.getItem("refreshToken");
    setAuth(() => {return isAuth})
    if(!isAuth && (!noAuth.includes(window.location.pathname.toLowerCase())))
      window.location.replace(`${AERoot}auth/login?redirect_to=${window.location.href}`)
  },[])

    return (
      <Route
        {...rest}
        render={(routeProps) =>{
          return (<Component {...routeProps} />)
          // if(isAuthenticated){
          //   return (<Component {...routeProps} />)
          // }
          // window.location.replace(`https://auth.excelmec.org/auth/login?redirect_to=${window.location.href}`)
          // return null;
                    
          // : (
          //   <Redirect
          //     to={{
          //       pathname: `https://staging.accounts.excelmec.org/auth/login?redirect_to=http://localhost:3000${routeProps.location}`,
          //       state: { from: routeProps.location }
          //     }}
          //   />
          }
        }
      />
    );
};

export default PrivateRoute;