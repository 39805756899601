import './App.scss';

/*eslint no-unused-vars: 0*/
import React, { Suspense } from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import Authorisation from './components/Authorisation/Authorisation';
// import GameOver from './pages/GameOver/GameOver';
import PrivateRoute from './components/common/PrivateRoute/PrivateRoute';
import Home from './pages/Home/Home';
// import Test from './pages/Test/Test';
// import SpellBee from './pages/SpellBee/SpellBee';
import Kryptos from './pages/Kryptos/Kryptos';
// import Dalalbull from './pages/DalalBull/DalalBull';
import Spinner from './components/common/Spinner/Spinner';
// import MiniGames from './pages/MiniGames/MiniGames';
import DoodleJump from './pages/DoodleJump/DoodleJump';
import Wordle from './pages/Wordle/Wordle';
function deleteCookie(name: string) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

const App = () => {
	const excelYear = "2024";
	const currentRefreshSavedYear = localStorage.getItem("refreshSavedYear");
	if (!currentRefreshSavedYear || currentRefreshSavedYear != excelYear) {
		localStorage.removeItem("refreshSavedYear");
		localStorage.removeItem("refreshToken");
		localStorage.removeItem("accessToken");
		deleteCookie('jwt_token');
		
		localStorage.setItem("refreshSavedYear", excelYear);
		console.log("A New year, A New Excel");
		window.location.reload();
	}

	return (
		<div className='main-background'>
			<Authorisation />
			<Suspense fallback={<Spinner />}>
				<Router>
					{/* <Route exact path='/' component={GameOver} /> */}
					<Route exact path='/' component={Home} />
					<PrivateRoute path={["/doodlejump"]} component={DoodleJump}/>
					<PrivateRoute path={["/wordle"]} component={Wordle}/>
					{/* <PrivateRoute path='/Dalalbull' component={Dalalbull} /> */}
					{/* <PrivateRoute exact path='/test' component={Test} /> */}
					{/* <PrivateRoute path='/spellbee' component={SpellBee} /> */}
					<PrivateRoute path='/Kryptos' component={Kryptos} />
				</Router>
			</Suspense>
		</div>
	);
};

export default App;
