import React, { useState, FunctionComponent, useEffect } from 'react';
import './GameHeader.scss';
import excel22_logo from '../../../assets/play2022.png';
import SideNav from './SideNav/SideNav';
import ghBtn from '../../../assets/ghButton.png'
import ham from '../../../assets/hamburger.png'


interface MIprops {
  link?: string;
  text: string;
}

const MItem: FunctionComponent<MIprops> = props => {
  const [page, setPage] = useState(false)

  useEffect(() => {
    let p = window.location.pathname
    let split = p.split('/');
    if(split.length > 2){
      if(split[2].toLowerCase() == props.text.toLowerCase())
        setPage(true)
    }
    else{
      if(props.text === "Play"){
        setPage(true)
      }
    }
  },[])
  const { link, text } = props;
  return (
    <li className="nav-item">
      <a className={page ? "nav-link active clicked" : "nav-link active"} href={link}>
        <span className="link-rotate">{text}</span>
      </a>
    </li>
  );
};

const MItem2: FunctionComponent<MIprops> = props => {
  const { link, text } = props;
  return (
    <li className="nav-item">
      <a className="nav-link active" href={link}>
        <img src = {ghBtn} width={"132px"}/>
        <span className='btnText'>{text}</span>
      </a>
    </li>
  );
};

interface GameProps {
  gName: string;
  icon: any;
}

const GameHeader: FunctionComponent<GameProps> = props => {
  const [sideMenuVisibility, setSideMenuVisibility] = useState(false);
  function toggleMenu(): void {
    setSideMenuVisibility(!sideMenuVisibility);
  }
  return (
    <div className="gameHeader">
      <div className="top-banner">
        <div className="row h-100">
          <div className="col-lg-4">
            <div className="d-flex">
              <div className="excel-play-logo">
                <a href="/">
                  <img
                    src={excel22_logo}
                    className="img img-fluid play-logo"
                    alt=""
                  />
                </a>
              </div>


            </div>
          </div>
          <div className="menu-icon">
            <img width={'40px'} src={ham} onClick={toggleMenu} />
          </div>
          <div 
          className={`${sideMenuVisibility.toString()} sideMenu`}>
            <SideNav
              toggleMenu={toggleMenu}
              sideMenuVisibility={sideMenuVisibility}
            >
              {props.children}
            </SideNav>
          </div>
          <div className="col-lg-8 d-none d-lg-block">
            <ul style={{
              marginTop: '1rem'
            }} className="nav justify-content-end nav-main">
              <div style={{ display: 'flex' }} className="border__style">

                {props.children}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GameHeader, MItem };

